import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { toLocaleDate } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Amount from '../common/amount'
import { darkNavy, navy, whitestBlue } from '../constants/colors'

const Period = styled.span`
  color: #00c0e6;
  font-weight: 800;
`

const Game = styled.span`
  font-size: 12px;
`

const Type = styled.span`
  font-size: 9px;
`

const Table = styled.table`
  color: #fff;
  font-size: 9px;
  background-color: ${darkNavy};
  width: 100%;

  td {
    padding: 6px 16px;
    vertical-align: middle;
    line-height: 16px;
  }

  td:nth-child(1) {
    background-color: ${navy};
    max-width: 320px;
    width: 100%;
  }

  td:nth-child(2) {
    text-align: center;
    background-color: ${whitestBlue};
  }

  td:nth-child(3) {
    text-align: center;
    background-color: ${navy};
  }

  tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.33);
  }
`

// TODO: Make a generic Table
function GamblingHistoryTable(props) {
  function getTransactionTitle(item, translate) {
    if (item.node.gameTitle) {
      return (
        <>
          {translate('gambling-history.game')}&nbsp;
          <b>{item.node.gameTitle}</b>
        </>
      )
    }
    if (item.node.customerReward) {
      return <b>{translate('gambling-history.customer-reward')}</b>
    }
    return (
      <>
        {translate('gambling-history.game')}&nbsp;
        <b>Freespins</b>
      </>
    )
  }

  return (
    <Table>
      <tbody>
        {props.totals && props.showTotals && (
          <React.Fragment>
            <tr>
              <td>{props.translate('gambling-history.total')}</td>
              <td>
                <b>{props.translate('gambling-history.stake')}</b>
                <br />
                <Amount fixed>{props.totals.betAmountCents}</Amount>
              </td>
              <td>
                <b>{props.translate('gambling-history.return')}</b>
                <br />
                <Amount fixed>{props.totals.winAmountCents}</Amount>
              </td>
            </tr>
            <tr>
              <td />
              <td colSpan="2">
                <b>{props.translate('gambling-history.difference')}</b>
                <br />
                <Amount fixed>
                  {Math.abs(
                    props.totals.betAmountCents - props.totals.winAmountCents
                  )}
                </Amount>
              </td>
            </tr>
            <tr>
              <td />
              <td colSpan="2">
                <b>{props.translate('gambling-history.return-to-player')}</b>
                <br />
                {!Number.isNaN(
                  props.totals.winAmountCents / props.totals.betAmountCents
                )
                  ? (
                      (props.totals.winAmountCents /
                        props.totals.betAmountCents) *
                      100
                    ).toFixed(2)
                  : 0}{' '}
                %
              </td>
            </tr>
          </React.Fragment>
        )}
        {props.items?.map((item, i) => {
          return (
            <tr key={i}>
              <td>
                <Period>
                  {
                    toLocaleDate(Number(item.node.createdAt), props.language)
                      .dateTime
                  }
                </Period>
                <br />
                <Game>{getTransactionTitle(item, props.translate)}</Game>
                <br />
                <Type>
                  {props.translate(
                    `gambling-history.${item.node.type}.bet-type`
                  )}
                </Type>
              </td>
              <td>
                <b>{props.translate('gambling-history.stake')}</b>
                <br />
                <Amount fixed>
                  {item.node.totalAmountCents < 0
                    ? Math.abs(item.node.totalAmountCents)
                    : 0}
                </Amount>
              </td>
              <td>
                <b>{props.translate('gambling-history.return')}</b>
                <br />
                <Amount fixed>
                  {item.node.totalAmountCents > 0
                    ? item.node.totalAmountCents
                    : 0}
                </Amount>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

GamblingHistoryTable.propTypes = {
  items: PropTypes.object.isRequired,
  totals: PropTypes.shape({
    betAmountCents: PropTypes.number,
    winAmountCents: PropTypes.number,
  }),
  language: PropTypes.string,
  translate: PropTypes.func.isRequired,
  showTotals: PropTypes.bool.isRequired,
}

export default withTranslate(GamblingHistoryTable)
