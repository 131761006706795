import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Box, Image, Link } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { TobiqueSeal } from '../tobique-seal'

import ApgSeal from './apg-seal'
import { XcmSeal } from './xcm-seal'

const LicenseImage = styled.img`
  max-height: 45px;
  margin-left: 6px;
  margin-right: 6px;
`

function LicenseLogos(props) {
  return (
    <Box
      display="inline-flex"
      justifyContent="inherit"
      alignItems="inherit"
      flexWrap="inherit"
    >
      {props.licenses.showAppSeal && (
        <Box size="50px" display="inline-block" verticalAlign="bottom" mx="8px">
          <XcmSeal />
        </Box>
      )}
      {props.licenses.showAppSeal && (
        <Box size="50px" display="inline-block" verticalAlign="bottom" mx="8px">
          <ApgSeal />
        </Box>
      )}
      {props.showTobiqueSeal ? (
        <Box size="50px" display="inline-block" verticalAlign="bottom" mx="8px">
          <TobiqueSeal />
        </Box>
      ) : (
        <Link
          to="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJaXRsZFVNd2RreE5VakZMVTA0cmVEQkdVbVZZYWtFOVBTSXNJblpoYkhWbElqb2lhazlYV0cxaU15dDJVM1pPTlhkWGNIaGlkV280VTBkdVJXVkpUVFZuT0RCcVRUQktjV0YzVFZJeGF6MGlMQ0p0WVdNaU9pSmxOamhtTnpFNFlUY3lNelV5TlRBeFpURXpZbUprTVRNNVpqSmlOV0UyTWpZMlpUQTFZell4T1dVNU1qRTNabUkyTXpaak5UVXdNR1l5WmpJek1UUmhJaXdpZEdGbklqb2lJbjA9"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LicenseImage
            size="fixed"
            height={45}
            src="../images/gcb.avif"
            alt="GCB license"
          />
        </Link>
      )}
      <Link to="https://www.gamblingtherapy.org/en" target="_blank">
        <LicenseImage
          size="fixed"
          height={45}
          src={props.translate('gamblingtherapy-logo')}
          alt="Gambling Therapy"
        />
      </Link>
      <Link to="/responsible-gaming" target="_blank">
        <Image
          size="fixed"
          height={45}
          src={props.translate('license.asset.18plus')}
          alt="18+"
        />
      </Link>
    </Box>
  )
}

export default withTranslate(LicenseLogos)

LicenseLogos.propTypes = {
  license: PropTypes.string.isRequired,
  licenses: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  showTobiqueSeal: PropTypes.bool,
}
