import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { goBack } from 'react-router-redux'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import styled from '@emotion/styled'
import { isExistingTranslation, withTranslate } from '@rushplay/i18n'

import HtmlContent from '../../common/html-content'
import Icon from '../../common/icon/icon'

import { Description, Header, Headline, NavigateBack, Wrapper } from './static'

const Content = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  margin-bottom: 50px;

  @media screen and (min-width: 768px) {
    padding: 30px 60px;
  }

  @media screen and (min-width: 1200px) {
    border-radius: 5px;
  }
`
function StaticPageContainer(props) {
  const licenseDescriptionKey = `static.${props.route.path}.${props.licenseId}.description`
  const descriptionKey = `static.${props.route.path}.description`

  const description = isExistingTranslation(licenseDescriptionKey)
    ? props.translate(licenseDescriptionKey)
    : isExistingTranslation(descriptionKey)
    ? props.translate(descriptionKey)
    : null

  return (
    <Wrapper>
      <Header>
        <Headline>
          {props.translate(`static.${props.route.path}.headline`)}
        </Headline>
        <NavigateBack onClick={props.onNavigateBack}>
          <Icon name="chevron-left" size="big" />
        </NavigateBack>

        {isExistingTranslation(`static.${props.route.path}.description`) && (
          <Description>
            <HtmlContent
              html={{
                __html: description,
              }}
            />
          </Description>
        )}
      </Header>

      <Content>
        {props.route.path === 'terms-conditions' ? (
          <HtmlContent
            html={{
              __html: isExistingTranslation(
                `static.${props.route.path}.${props.licenseId}.content`
              )
                ? props.translate(
                    `static.${props.route.path}.${props.licenseId}.content`,
                    {
                      logoImageSrc: props.translate(
                        'terms-and-conditions.general.license-logo'
                      ),
                    }
                  )
                : props.translate(`static.${props.route.path}.content`, {
                    logoImageSrc: props.translate(
                      'terms-and-conditions.general.license-logo'
                    ),
                  }),
            }}
          />
        ) : (
          <HtmlContent
            html={{
              __html: isExistingTranslation(
                `static.${props.route.path}.${props.licenseId}.content`
              )
                ? props.translate(
                    `static.${props.route.path}.${props.licenseId}.content`
                  )
                : props.translate(`static.${props.route.path}.content`),
            }}
          />
        )}
      </Content>
    </Wrapper>
  )
}

function mapStateToProps(state) {
  return {
    licenseId: jurisdiction.getLicense(state.jurisdiction),
  }
}

export default connect(mapStateToProps, { onNavigateBack: goBack })(
  withTranslate(StaticPageContainer)
)

StaticPageContainer.propTypes = {
  licenseId: PropTypes.string,
  route: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  onNavigateBack: PropTypes.func.isRequired,
}
