import * as React from 'react'
import { Helmet } from 'react-helmet-async'

export function TobiqueSeal() {
  //TODO: get the seal id from BE
  const sealId = 'd116935d-b978-40a0-944b-d33716b7cee2'

  React.useEffect(() => {
    if (window.dlag_d116935d_b978_40a0_944b_d33716b7cee2) {
      window?.dlag_d116935d_b978_40a0_944b_d33716b7cee2.init()
    }
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <script
          type="text/javascript"
          src={`https://${sealId}.seals.dlagglobal.com/dlag-seal.js`}
        />
      </Helmet>
      <div
        id={`dlag-${sealId}`}
        data-dlag-seal-id={sealId}
        data-dlag-image-size="45"
        data-dlag-image-type="basic-small"
      ></div>
    </React.Fragment>
  )
}
