import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Box, Link, Space } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import * as App from '../../store/app'

const FooterLink = styled(Link)`
  opacity: 0.5;
  font-size: 14px;
  line-height: 24px;
`

const MailContactLink = styled.a`
  opacity: 0.5;
  font-size: 14px;
  line-height: 24px;
`

export function FooterSection(props) {
  const i18n = useI18n()
  const brand = ReactRedux.useSelector((state) => App.getBrand(state.app))

  return (
    <Box
      width={['50%', 'auto']}
      paddingBottom="32px"
      paddingRight={[null, '90px']}
    >
      <Box paddingBottom={2} fontSize={[1, null, null, null, 2]}>
        {i18n.translate(props.heading)}
      </Box>
      {R.map(
        (link) => (
          <Space key={link.label} top={4}>
            {link.label === 'footer.links.contact-by-email' ? (
              <MailContactLink
                href={`mailto:${i18n.translate(`support-email.${brand}`)}`}
              >
                {i18n.translate(link.label)}
              </MailContactLink>
            ) : (
              <FooterLink to={link.to}>{i18n.translate(link.label)}</FooterLink>
            )}
          </Space>
        ),
        props.sectionLinks
      )}
    </Box>
  )
}

FooterSection.propTypes = {
  heading: PropTypes.string.isRequired,
  sectionLinks: PropTypes.array.isRequired,
}
