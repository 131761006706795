import PropTypes from 'prop-types'
import React from 'react'

import { Box, Space } from '@rushplay/common'

import { LanguageSelector } from '../../language-selector'

import { LegalInfo } from './legal-info'
import { Logo } from './logo'
import { Providers } from './providers'
import { Sitemap } from './sitemap'

export function Footer(props) {
  if (props.location?.pathname?.match('starsplaypromo')) {
    return null
  }

  return (
    <Box data-id="footer" width="100%" backgroundColor="#02102A" color="white">
      <Box px={[4, null, null, null, 0]} py={[8, null, null, null, 10]}>
        <Box
          maxWidth="1200px"
          margin="auto"
          display="flex"
          flexDirection={['column', null, null, null, 'row']}
        >
          <Space paddingRight={[null, null, null, null, '66px']}>
            <Logo />
            <LanguageSelector />
          </Space>

          <Box>
            <Sitemap />
            {props.paymentProviders && (
              <Providers providers={props.paymentProviders} />
            )}
            {props.gameProviders && (
              <Providers providers={props.gameProviders} />
            )}
            <LegalInfo />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

Footer.propTypes = {
  gameProviders: PropTypes.array,
  paymentProviders: PropTypes.array,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}
