import * as Urql from 'urql'
import * as React from 'react'

const getPlayerGameHistoryQuery = `
  query PlayerGameHistory($after: String, $startDateTimestamp: Float, $endDateTimestamp: Float) {
    player {
      gamblingHistory {
        transactions(after: $after, startDateTimestamp: $startDateTimestamp, endDateTimestamp: $endDateTimestamp) {
          edges {
            node {
              totalAmountCents
              type
              customerReward
              createdAt
              gameTitle
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
        totals {
          betAmountCents
          winAmountCents
        }
      }
    }
  }
`

export function useFetchPaginatedHistory(props) {
  const [firstTimeFilter, setFirstTimeFilter] = React.useState(true)
  const [cursor, setCursor] = React.useState(props.cursor)
  const [filter, setFilter] = React.useState(null)

  // keeping transaction in state to append more data to existing one
  const [transactions, setTransactions] = React.useState([])

  const [response] = Urql.useQuery({
    query: getPlayerGameHistoryQuery,
    requestPolicy: 'network-only',
    variables: {
      after: cursor || props.cursor,
      startDateTimestamp: filter?.startDate,
      endDateTimestamp: filter?.endDate,
    },
  })

  const totals = React.useMemo(
    () => response.data?.player?.gamblingHistory?.totals,
    [response.data?.player?.gamblingHistory]
  )

  const pageInfo = React.useMemo(
    () => response.data?.player?.gamblingHistory?.transactions?.pageInfo,
    [response.data?.player?.gamblingHistory]
  )

  function handleClearFilter() {
    setFilter(null)
    setCursor(null)
    setFirstTimeFilter(true)
    setTransactions([])
  }

  React.useEffect(() => {
    if (
      Boolean(filter) &&
      firstTimeFilter &&
      response.data?.player?.gamblingHistory
    ) {
      // clear transactions if filter is on
      setTransactions(
        response.data?.player?.gamblingHistory?.transactions?.edges || []
      )
      setFirstTimeFilter(false)
    }

    if (
      (!filter && response.data?.player?.gamblingHistory) ||
      (Boolean(filter) &&
        !firstTimeFilter &&
        response.data?.player?.gamblingHistory)
    ) {
      // append transactions to existing if filter is off or filter is on and we request more
      setTransactions([
        ...transactions,
        ...(response.data?.player?.gamblingHistory?.transactions?.edges || []),
      ])
    }
  }, [response.data?.player?.gamblingHistory])

  function handleFilter({ startDate, endDate }) {
    setFirstTimeFilter(true)
    setFilter({
      startDate: new Date(startDate).setUTCHours(23),
      endDate: new Date(endDate).setUTCHours(23),
    })
  }

  function loadMore() {
    if (pageInfo?.endCursor) {
      setCursor(pageInfo?.endCursor)
    }
  }

  return {
    handleFilter,
    handleClearFilter,
    loadMore,
    transactions,
    totals,
    pageInfo,
    fetching: Boolean(response?.fetching),
    noEdges: Boolean(transactions?.length === 0),
    isFiltered: Boolean(filter),
    firstTimeFilter,
  }
}
